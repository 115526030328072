<script>
import { getAudioPrompt } from "@/api/services";

export default {
  name: "GenerateAudio",
  data() {
    return {
      generatingAudio: false,
      promptAudioFile: "",
      promptAtAudioFileCreation: ""
    };
  },
  methods: {
    generateAudio(
      promptText,
      generatingAudio,
      promptAudioFile,
      promptAtAudioFileCreation
    ) {
      if (this[promptText]) {
        this[generatingAudio] = true;
        getAudioPrompt({ prompt_text: this[promptText], task_id: this.task_id })
          .then(({ data }) => {
            this[promptAudioFile] = data.audio_url;
            this[promptAtAudioFileCreation] = this[promptText];
            this[generatingAudio] = false;
          })
          .catch(err => {
            this[generatingAudio] = false;
            this.$message({
              type: "error",
              message: err.message
            });
          });
      } else {
        this[promptAudioFile] = "";
      }
    }
  }
};
</script>
